<template>
	<div class="toggle-container">
		<div
			class="toggle"
			:class="{ 'toggle-disabled': disabled }"
			role="checkbox"
			@keydown.enter="toggleListener"
			@keydown.space="toggleListener"
			@click.stop="toggleListener"
			tabindex="0"
			:aria-checked="localChecked"
			:title="title"
		>
			<span class="fade-wrapper">
				<transition name="fade">
					<Fa
						v-if="localChecked"
						key="on"
						weight="r"
						icon="toggle-large-on"
						:class="{ 'toggle-fa-disabled': disabled }"
					/>
					<Fa v-else key="off" weight="r" icon="toggle-large-off" :class="{ 'toggle-disabled': disabled }" />
				</transition>
			</span>
			<slot></slot>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ToggleCheckbox',
	model: {
		prop: 'checked',
		event: 'changed',
	},
	props: {
		checked: [Boolean, Array],
		value: {},
		disabled: Boolean,
		title: String,
	},
	computed: {
		localChecked() {
			if (Array.isArray(this.checked)) {
				return this.checked.includes(this.value);
			}

			return this.checked;
		},
	},
	methods: {
		toggleListener() {
			if (this.disabled) {
				return;
			}

			if (Array.isArray(this.checked)) {
				let checked = [].concat(this.checked);
				let index = checked.indexOf(this.value);

				if (index >= 0) {
					checked.splice(index, 1);
				} else {
					checked.push(this.value);
				}

				this.$emit('changed', checked);
			} else {
				this.$emit('changed', !this.checked);
			}
		},
	},
};
</script>

<!--suppress CssUnusedSymbol -->
<style scoped>
.toggle-container {
	display: block;
}

.toggle {
	display: inline-block;
	font-size: 13px;
	font-weight: 700;
	cursor: pointer;
}

.toggle-disabled {
	color: #969696;
	cursor: not-allowed;
}

.fade-wrapper {
	position: relative;
	display: inline-block;
	width: 20px;
	height: 14px;
	margin-right: 2px;
	vertical-align: text-bottom;
}

.fap {
	position: absolute;
	font-size: 18px;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-active {
	opacity: 0;
}

.fa-toggle-large-off {
	color: #969696;
}

.fa-toggle-large-on {
	color: #169f41;
}

.toggle-fa-disabled {
	opacity: 0.4;
}
</style>
