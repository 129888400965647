<template>
	<masonry :cols="{ default: cols, 800: 1 }" :gutter="gutter" class="masonry-row">
		<slot></slot>
	</masonry>
</template>

<script>
export default {
	name: 'MasonryLayout',
	props: {
		cols: {
			type: Number,
			default: 2,
		},
		gutter: {
			type: Number,
			default: 15,
		},
	},
};
</script>

<style>
.masonry-row > * {
	display: flex;
	flex-direction: column;
	gap: var(--panelGap);
}
.masonry-row .accordion {
	margin: 0 0 10px 0;
}
</style>
