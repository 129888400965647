<template>
	<div class="form-group">
		<label :for="$attrs.id">
			{{ label }}
			<span v-if="required">*</span>
		</label>
		<div class="flex">
			<VueSelect
				class="app-select"
				label="name"
				:value="null"
				:options="options"
				:filterable="false"
				:placeholder="placeholder"
				:inputId="$attrs.id"
				@input="selectedListener"
				style="flex: 1"
			>
				<template v-slot:no-options>Skriv in bolagets namn för att söka</template>
				<template v-slot:option="slotProps">{{ slotProps.name }}</template>
			</VueSelect>
			<button v-if="canSelectAll" type="button" @click="selectAllCompaniesListener" style="max-width: 80px">
				Välj alla
			</button>
		</div>

		<ul class="multiselect-list">
			<li v-for="company in companies" :key="company.id">
				{{ company.name }}

				<button
					v-if="hasCompany(company.id)"
					@click.prevent="deleteListener(company.id)"
					class="button-seamless"
				>
					<Fa weight="r" icon="times" class="t-disabled" />
				</button>
			</li>
		</ul>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	name: 'AppFormCompanySelect',
	props: {
		value: {
			type: Array,
			default: () => [],
		},
		label: {
			type: String,
			default: 'Bolag',
		},
		placeholder: {
			type: String,
			default: 'Sök / Lägg till bolag',
		},
		required: {
			type: Boolean,
			default: false,
		},
		canSelectAll: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			selectAllCompanies: false,
		};
	},
	computed: {
		...mapGetters({
			canAccessAllCompanies: 'auth/canAccessAllCompanies',
			userCompanies: 'auth/allowedCompanies',
			allCompanies: 'company/all',
		}),
		companies: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
		options() {
			const filter = (company) => !this.companies.find((c) => c.id === company.id);

			if (this.canAccessAllCompanies) {
				return this.allCompanies.filter(filter);
			}

			return this.userCompanies.filter(filter);
		},
	},
	methods: {
		async selectAllCompaniesListener() {
			this.options.forEach((company) => this.selectedListener(company));
		},
		selectedListener(company) {
			if (!this.companies.length) {
				this.companies.push(company);
			} else if (this.companies.findIndex((c) => c.id === company.id) < 0) {
				this.companies.push(company);
			}
		},
		deleteListener(companyId) {
			this.companies = this.companies.filter((c) => c.id !== companyId);
			this.selectAllCompanies = false;
		},
		hasCompany(id) {
			if (this.canAccessAllCompanies) {
				return true;
			}

			return this.userCompanies.find((company) => company.id === id);
		},
	},
};
</script>
