import httpRepo from '@/core/httpRepo';

export default {
	...httpRepo('project'),
	putStatus(id, status, data = {}) {
		return this.put(`${id}/status`, {
			status,
			...data,
		});
	},
	putHoursEstimate(id, data) {
		return this.put(`${id}/hours_estimate`, data);
	},
	getOverview(repoParams) {
		const params = new URLSearchParams(repoParams).toString();
		if (Object.entries(repoParams).length) {
			return this.get(`overview?${params}`);
		}
		return this.get('overview');
	},
	getIsUserInProject(id, userId) {
		return this.get(`${id}/is_user_in_project/${userId}`);
	},
	getProjectOverview(id) {
		return this.get(`${id}/overview`);
	},
};
