<template>
	<li class="list__row" @click="$emit('rowClick')">
		<slot>
			<div class="list__row__content" @click="$emit('contentClick')">
				<p class="list__row__title">
					<slot name="preTitle" />
					<template v-if="boldTitle">
						<b>{{ title }}</b>
					</template>
					<template v-else>
						{{ title }}
					</template>
				</p>
				<p class="list__row__muted" v-if="$slots.muted || muted?.length">
					<slot name="muted">
						{{ muted }}
					</slot>
				</p>
			</div>
		</slot>

		<div v-if="$slots.actions" class="list__row__actions">
			<slot name="actions"></slot>
		</div>
	</li>
</template>

<script>
export default {
	name: 'AppListItem',
	props: {
		title: {
			type: String,
		},
		boldTitle: {
			type: Boolean,
			default: false,
		},
		muted: {
			type: String,
		},
	},
};
</script>

<style scoped>
.list__row {
	display: flex;
	align-items: center;
	gap: 10px;
	width: 100%;
	padding: 8px 0;
}

.list__row:not(:last-of-type) {
	border-bottom: 1px solid var(--colorBorder);
}

.list__row__actions {
	display: flex;
	align-items: center;
	gap: 10px;
	margin-left: auto;
}

.list--narrow .list__row {
	padding: 8px 0;
}

.list__row__content {
	display: flex;
	flex-direction: column;
	gap: 2px;
	width: 100%;
}

.list__row__title {
	display: flex;
	align-items: center;
	gap: 4px;
	margin: 0;
}

.list__row__muted {
	margin: 0;
	font-size: var(--fontSizeSmall);
	color: var(--colorMuted);
}
</style>
