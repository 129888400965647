import * as types from '@/mutationTypes';

export default {
	namespaced: true,
	state: () => ({
		repoParams: null,
		pageOffset: null,
	}),
	getters: {
		repoParams(state) {
			return state.repoParams;
		},
		companiesAndDatesRepoParams(state) {
			if (state.repoParams === null) {
				return null;
			}

			const { is_dispute, is_testified_bookkeep, evoyce_need_feedback, is_booked, is_due, is_paid, ...rest } =
				state.repoParams;

			return rest;
		},
		pageOffset(state) {
			return state.pageOffset;
		},
	},
	mutations: {
		[types.supplierInvoice.SET_REPO_PARAMS]: (state, data) => {
			state.repoParams = data;
		},
		[types.supplierInvoice.SET_PAGE_OFFSET]: (state, data) => {
			state.pageOffset = data.pageOffset;
		},
	},
};
