<template>
	<div class="form-group">
		<label :for="$attrs.id">{{ label }}</label>
		<AppFileUploadInput class="mt-1" v-bind="$attrs" @filesSelected="emitFilesSelected" v-if="!disabled" />

		<ul v-if="attachments.length" class="attachment-list">
			<li v-for="(attachment, index) in attachments" :key="index">
				<Fa weight="r" icon="paperclip" />
				<span v-if="attachment.id">
					<a :href="attachment.file_url" target="_blank">{{ attachment.name }}</a>
				</span>
				<span v-else>{{ attachment.file.name }}</span>

				<button @click.prevent="$emit('deleteAttachment', index)" v-if="!disabled">
					<Fa weight="r" icon="times" class="t-disabled" />
				</button>
			</li>
		</ul>
	</div>
</template>

<script>
export default {
	name: 'AppFormAttachments',
	inheritAttrs: false,
	props: {
		label: {
			type: String,
			default: '',
		},
		attachments: {
			type: Array,
			default: () => [],
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		emitFilesSelected(files) {
			this.$emit('filesSelected', files);
		},
	},
};
</script>
