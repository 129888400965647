import EventEmitter from 'events';
import isFocusable from '@/core/isFocusable';

const events = new EventEmitter();
const stack = [];
let ignoreFocus = false;
let focusTrapInitiated = false;

const focusFirstDescendant = (dialog) => {
	let i;
	let child;

	for (i = 0; i < dialog.childNodes.length; i++) {
		child = dialog.childNodes[i];
		if (attemptFocus(child) || focusFirstDescendant(child)) {
			return true;
		}
	}

	return false;
};

const focusLastDescendant = (dialog) => {
	let i;
	let child;

	for (i = dialog.childNodes.length - 1; i >= 0; i--) {
		child = dialog.childNodes[i];
		if (attemptFocus(child) || focusLastDescendant(child)) {
			return true;
		}
	}

	return false;
};

const attemptFocus = (element) => {
	if (!isFocusable(element)) {
		return false;
	}

	ignoreFocus = true;

	try {
		element.focus();
	} catch (e) {
		// Do nothing
	}

	ignoreFocus = false;

	return document.activeElement === element;
};

const trapFocusListener = (e) => {
	if (!stack.length || ignoreFocus || e.target === document) {
		return;
	}

	const currentDialog = stack[stack.length - 1];
	const currentDialogContent = currentDialog.$el.querySelector('.modal');

	if (e.target instanceof Node && currentDialogContent.contains(e.target)) {
		currentDialog.lastFocus = e.target;
	} else {
		focusFirstDescendant(currentDialogContent);
		if (currentDialog.lastFocus === document.activeElement) {
			focusLastDescendant(currentDialogContent);
		}

		currentDialog.lastFocus = document.activeElement;
	}
};

export default {
	events,

	addToStack(modal) {
		stack.push(modal);

		if (!focusTrapInitiated) {
			addEventListener('focus', trapFocusListener, true);
			focusTrapInitiated = true;
		}
	},
	popStack() {
		stack.pop();
	},
};
