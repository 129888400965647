import * as types from '@/mutationTypes';

export default {
	namespaced: true,
	state: () => ({
		statusLinks: [],
	}),
	getters: {
		statusLinks(state) {
			return state.statusLinks;
		},
	},
	actions: {
	},
	mutations: {
		[types.sharepointItemStatus.ADD_STATUS_URL]: (state, data) => {
			state.statusLinks.push(data);
		},
		[types.sharepointItemStatus.REMOVE_STATUS_URL]: (state, data) => {
			const index = state.statusLinks.findIndex((d) => d.item.id === data.item.id);
			if (index !== -1) {
				state.statusLinks.splice(index, 1);
			}
		},
	},
};
