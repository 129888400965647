<template>
	<p class="form-group">
		<label :for="$attrs.id" v-if="label">
			{{ label }}
			<span v-if="required">* </span>
			<span v-if="$attrs.maxlength" class="t-neutral"> ({{ lengthLeft }}) </span>
		</label>
		<textarea
			v-bind="$attrs"
			:placeholder="placeholder ? placeholder : label"
			:value="value"
			@input="$emit('input', $event.target.value)"
			:required="required"
		/>
	</p>
</template>

<script>
export default {
	name: 'AppFormTextarea',
	props: {
		label: String,
		value: {
			type: String,
			default: '',
		},
		required: Boolean,
		placeholder: String,
	},
	computed: {
		lengthLeft() {
			return this.$attrs.maxlength - this.value.length;
		},
	},
};
</script>
