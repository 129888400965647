<template>
	<p class="form-group">
		<label :for="$attrs.id">
			{{ label }}
			<span v-if="$attrs.required"> *</span>
		</label>
		<select
			:name="$attrs.id"
			:id="$attrs.id"
			v-on="listeners"
			v-bind="$attrs"
			:value="value"
			:placeholder="placeholder"
		>
			<slot></slot>
		</select>
	</p>
</template>

<script>
export default {
	name: 'AppFormSelectEntity',
	inheritAttrs: false,
	props: {
		value: {
			type: String,
			default: '',
		},
		label: {
			type: String,
			default: '',
		},
	},
	computed: {
		listeners() {
			return {
				...this.$listeners,
				input: (event) => {
					this.$emit('input', event.target.value);
				},
			};
		},
		placeholder() {
			return this.$attrs?.placeholder ?? this.label;
		},
	},
};
</script>

<style>
.form-group label {
	padding: 0 0 5px 0;
}
</style>
