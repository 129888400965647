import * as types from '@/mutationTypes';
import me from '@/modules/me/me';

const COUNTS_UPDATE_FREQUENCY = 300_000; // 5 minutes

export default {
	namespaced: true,
	state: () => ({
		awaitCounts: undefined,
		awaitCountsTimer: undefined,
	}),
	getters: {
		awaitCounts(state) {
			return state.awaitCounts;
		},
	},
	actions: {
		async loadAwaitCounts({ commit, state, rootState }, now) {
			if (state.awaitCountsTimer !== undefined && now - state.awaitCountsTimer < COUNTS_UPDATE_FREQUENCY) {
				return;
			}

			if (!rootState.auth.authenticatedUser) {
				return;
			}

			commit(types.testify.SET_AWAIT_COUNTS_TIMER, now);

			const counts = await me.getAwaitTestifyCounts();
			commit(types.testify.SET_AWAIT_COUNTS, counts);
		},
		async refreshAwaitCounts({ commit }) {
			const counts = await me.getAwaitTestifyCounts();
			commit(types.testify.SET_AWAIT_COUNTS, counts);
		},
	},
	mutations: {
		[types.testify.SET_AWAIT_COUNTS](state, counts) {
			state.awaitCounts = counts;
		},
		[types.testify.SET_AWAIT_COUNTS_TIMER](state, time) {
			state.awaitCountsTimer = time;
		},
		[types.testify.RESET_AWAIT_COUNTS_TIMER](state) {
			state.awaitCountsTimer = undefined;
		},
	},
};
