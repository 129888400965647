<template>
	<i :class="['fap', weightClass, iconClass]" aria-hidden="true"></i>
</template>

<script>
export default {
	name: 'Fa',
	props: {
		weight: String,
		icon: String,
	},
	computed: {
		weightClass() {
			return 'fa' + this.weight;
		},
		iconClass() {
			return 'fa-' + this.icon;
		},
	},
};
</script>
