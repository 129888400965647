<template>
	<label class="switch-container" tabindex="0" @keyup.space="toggleListener" @keyup.enter="toggleListener">
		<span class="switch">
			<input type="checkbox" v-on="listeners" v-bind="$attrs" v-model="checked" />
			<div class="round"></div>
			<span class="label">
				<slot></slot>
			</span>
		</span>
	</label>
</template>

<script>
export default {
	name: 'ToggleCheckbox2',
	inheritAttrs: false,
	emits: ['input', 'change', 'changed'],
	props: {
		value: {
			type: Boolean,
		},
		title: {
			type: String,
			default: '',
		},
	},
	computed: {
		checked: {
			set(val) {
				this.$emit('input', val);
			},
			get() {
				return this.value;
			},
		},
		listeners() {
			return {
				...this.$listeners,
				input: (event) => {
					this.$emit('input', event.target.checked);
				},
				change: (event) => {
					this.$emit('change', event);
				},
			};
		},
	},
	methods: {
		toggleListener() {
			this.checked = !this.checked;
		},
	},
};
</script>

<style scoped>
.switch-container {
	display: flex;
	align-items: center;
	margin: 0;
	cursor: pointer;
}

.switch {
	position: relative;
	display: inline-block;
	width: 21px;
	height: 14px;
	background-color: inherit;
	border-radius: 10px;

	border: 2px solid #969696;
	transition: all 200ms ease-in-out;

	font-size: 13px;
}

.switch .round {
	position: absolute;
	height: 14px;
	width: 14px;
	border: 2px solid #969696;
	border-radius: 100%;
	background-color: inherit;

	left: -2px;
	bottom: -2px;

	transition: all 200ms ease-in-out;
}

.switch input[type='checkbox'] {
	visibility: hidden;
}

.switch:has(input[type='checkbox']:checked) {
	border-color: #169f41;
	background-color: #169f41;
}

.switch input[type='checkbox']:checked ~ .round {
	border-color: #169f41;
	background-color: #fff;
	transform: translateX(7px);
}

.switch:has(input[type='checkbox']:disabled) {
	cursor: not-allowed;
}

.switch .label {
	position: absolute;
	left: 24px;
	top: 50%;
	transform: translateY(-50%);
	text-wrap: nowrap;
}
</style>
