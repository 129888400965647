// App
export const app = {
	INCREMENT_LOADING_COUNT: 'INCREMENT_LOADING_COUNT',
	DECREMENT_LOADING_COUNT: 'DECREMENT_LOADING_COUNT',
	SET_APP_VERSION: 'SET_APP_VERSION',
	SET_LAST_SENTRY_EVENT_ID: 'SET_LAST_SENTRY_EVENT_ID',
	SET_SUB_MENU_LINKS: 'SET_SUB_MENU_LINKS',
	PURGE_SUB_MENU_LINKS: 'PURGE_SUB_MENU_LINKS',
	TOGGLE_HEADER_DROPDOWNS: 'TOGGLE_HEADER_DROPDOWNS',
	SET_LATEST_URL_DATA: 'SET_LATEST_URL_DATA',
	TOGGLE_SUB_MENU: 'TOGGLE_SUB_MENU',
	CLOSE_SUB_MENU: 'CLOSE_SUB_MENU',
	TOGGLE_MAIN_MENU: 'TOGGLE_MAIN_MENU',
	CLOSE_MAIN_MENU: 'CLOSE_MAIN_MENU',
	TOGGLE_PERSONAL_MENU: 'SHOW_PERSONAL_MENU',
	CLOSE_PERSONAL_MENU: 'CLOSE_PERSONAL_MENU',
};

// Auth
export const auth = {
	LOGIN_SUCCESS: 'LOGIN_SUCCESS',
	LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
	SET_LOGIN_REDIRECT: 'SET_LOGIN_REDIRECT',
	SET_TIMEREPORT_LOCK: 'SET_TIMEREPORT_LOCK',
	SET_EMPLOYMENT: 'SET_EMPLOYMENT',
	SET_WARNINGS: 'SET_WARNINGS',
};

// Company
export const company = {
	LOAD_ALL_SUCCESS: 'LOAD_ALL_SUCCESS',
	SET_PUBLIC_COMPANY: 'SET_PUBLIC_COMPANY',
};

// Office
export const office = {
	LOAD_ALL_SUCCESS: 'LOAD_ALL_SUCCESS',
};

// Accounting year
export const accountingYear = {
	LOAD_ALL_SUCCESS: 'LOAD_ALL_SUCCESS',
};

// Testify
export const testify = {
	SET_AWAIT_COUNTS: 'SET_AWAIT_COUNTS',
	SET_AWAIT_COUNTS_TIMER: 'SET_AWAIT_COUNTS_TIMER',
	RESET_AWAIT_COUNTS_TIMER: 'RESET_AWAIT_COUNTS_TIMER',
};

// Project
export const project = {
	LOAD_PROJECT_SUCCESS: 'LOAD_PROJECT_SUCCESS',
	PURGE_PROJECT: 'PURGE_PROJECT',
	LOAD_PROJECT_USERS_SUCCESS: 'LOAD_PROJECT_USERS_SUCCESS',
	PURGE_PROJECT_USERS: 'PURGE_PROJECT_USERS',
	SET_PROJECT_TYPE_OF_PROJECT: 'SET_PROJECT_TYPE_OF_PROJECT',
	SET_PROJECT_QUOTATION_DATA: 'SET_PROJECT_QUOTATION_DATA',
	SET_REDIRECT_URL: 'SET_REDIRECT_URL',
	CLEAR_REDIRECT_URL: 'CLEAR_REDIRECT_URL',
	SET_STATUS: 'SET_STATUS',
};

//Motorpool
export const motorpool = {
	LOAD_VEHICLE_SUCCESS: 'LOAD_VEHICLE_SUCCESS',
	PURGE_VEHICLE: 'PURGE_VEHICLE',
	LOAD_ATTACHMENTS_SUCCESS: 'LOAD_ATTACHMENTS_SUCCESS',
	PURGE_ATTACHMENTS: 'PURGE_ATTACHMENTS',
	LOAD_AUTOMILE_VEHICLE_SUCCESS: 'LOAD_AUTOMILE_VEHICLE_SUCCESS',
	PURGE_AUTOMILE_VEHICLE: 'PURGE_AUTOMILE_VEHICLE',
	LOAD_VENDOR_LOGS_SUCCESS: 'LOAD_VENDOR_LOGS_SUCCESS',
	PURGE_VENDOR_LOGS: 'PURGE_VENDOR_LOGS',
	LOAD_UPDATE_NEED_SUCCESS: 'LOAD_UPDATE_NEED_SUCCESS',
	PURGE_UPDATE_NEED: 'PURGE_UPDATE_NEED',
};

// ProjectEmail
export const projectEmail = {
	LOAD_EMAIL_SUCCESS: 'LOAD_EMAIL_SUCCESS',
};

// ProjectEntry
export const projectEntry = {
	LOAD_ENTRY_SUCCESS: 'LOAD_ENTRY_SUCCESS',
	REPLACE_ENTRY_SUCCESS: 'REPLACE_ENTRY_SUCCESS',
	REMOVE_ENTRY_SUCCESS: 'REMOVE_ENTRY_SUCCESS',
};

// SupplierInvoice
export const supplierInvoice = {
	SET_REPO_PARAMS: 'SET_REPO_PARAMS',
	SET_PAGE_OFFSET: 'SET_PAGE_OFFSET',
};

// CustomerInquiry
export const customerInquiry = {
	ADD_REQUEST: 'ADD_REQUEST',
	REMOVE_REQUEST: 'REMOVE_REQUEST',
};

export const sharepointItemStatus = {
	ADD_STATUS_URL: 'ADD_STATUS_URL',
	REMOVE_STATUS_URL: 'REMOVE_STATUS_URL',
};
