<template>
	<div :class="{ 'inline-block': inline }">
		<a :href="`tel:${tel}`" v-if="tel" class="tel-number">{{ tel }}</a>
		<span v-else>-</span>
	</div>
</template>

<script>
export default {
	name: 'AppAnchorTel',
	props: {
		tel: String,
		inline: {
			type: Boolean,
			default: false,
		},
	},
};
</script>

<style scoped>
.tel-number {
	text-decoration: underline;
	font-size: 13px;
	color: #043464;
	font-weight: 400;
}

.inline-block {
	display: inline-block;
}
</style>
