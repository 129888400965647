<template>
	<transition name="modal-fade" appear>
		<div class="modal-mask">
			<transition name="modal-container" appear>
				<div
					class="modal"
					tabindex="0"
					:class="{ large, medium, 'modal--app-bg': appBg, 'modal--photo': isPhoto }"
				>
					<header class="modal-header" v-if="header">
						<slot name="header"></slot>
						<div v-if="actions" class="modal-header-actions">
							<slot name="actions"></slot>
							<button
								type="button"
								@click="clickCloseListener"
								title="Avbryt"
								class="modal-close-btn"
								v-if="!hideCloseButton"
							>
								<Fa weight="r" icon="times" class="t-neutral" />
							</button>
						</div>
					</header>
					<div class="modal-body">
						<slot></slot>
					</div>
					<footer class="modal-footer" v-if="footer">
						<slot name="footer">
							<button type="button" v-if="!emptyFooter" @click="clickCloseListener" class="t-success">
								<Fa weight="r" icon="check-circle"></Fa>
								OK
							</button>
						</slot>
					</footer>
				</div>
			</transition>
		</div>
	</transition>
</template>

<script>
import appModalControl from '@/core/appModalControl';

export default {
	name: 'AppModal',
	props: {
		header: {
			type: Boolean,
			default: true,
		},
		footer: {
			type: Boolean,
			default: true,
		},
		emptyFooter: {
			type: Boolean,
			default: false,
		},
		medium: {
			type: Boolean,
			default: false,
		},
		large: {
			type: Boolean,
			default: false,
		},
		actions: {
			type: Boolean,
			default: true,
		},
		appBg: {
			type: Boolean,
			default: false,
		},
		isPhoto: {
			type: Boolean,
			default: false,
		},
		hideCloseButton: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			lastFocus: document.activeElement,
		};
	},
	methods: {
		clickCloseListener() {
			this.$emit('close');
		},
		keyUpListener(event) {
			if ((event.code && event.code === 'Escape') || event.keyCode === 27) {
				this.clickCloseListener();
			}
		},
	},
	mounted() {
		this.keyUpListener = this.keyUpListener.bind(this);
		addEventListener('keyup', this.keyUpListener);

		appModalControl.events.emit('appModalOpened');
		appModalControl.addToStack(this);
	},
	destroyed() {
		removeEventListener('keyup', this.keyUpListener);

		appModalControl.events.emit('appModalClosed');
		appModalControl.popStack();

		if (this.lastFocus) {
			this.lastFocus.focus();
		}
	},
};
</script>

<style>
.modal-mask {
	position: fixed;
	z-index: 99999;
	inset: 0;
	background: rgb(1, 10, 18, 0.5);
	transition: opacity 0.3s ease;
}

.modal {
	display: flex;
	flex-direction: column;
	width: 100%;
	min-height: 100%;
	/* mobile viewport bug fix */
	/*min-height: -webkit-fill-available;*/
	background: #fff;
	border-radius: 8px;
	overflow: hidden;
	transition: all 0.3s ease;
}

.modal-header {
	position: sticky;
	display: flex;
	top: 0;
	background: #fff;
	padding: 16px 20px 0;
	align-items: baseline;
	justify-content: space-between;
	z-index: 1;
}

.modal--app-bg,
.modal--app-bg > .modal-header {
	background: #f1f2f6;
}

.modal--photo {
	position: relative;
}

.modal--photo .modal-body {
	padding: 0;
}

.modal--photo .modal-header {
	position: absolute;
	background: transparent;
	top: 0;
	right: 0;
	width: 100%;
}

.modal-header > h1 {
	flex: 1;
}

.modal-header-actions {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 16px;
	margin-left: auto;
}

.modal-body {
	display: flex;
	flex-direction: column;
	gap: 15px;
	padding: 15px 20px 15px 20px;
	overflow-y: auto;
	/* mobile viewport bug fix */
	/*max-height: -webkit-fill-available;*/
}

.modal-body form {
	display: flex;
	flex-direction: column;
	gap: var(--panelGap);
}

.modal-footer {
	padding: 0 20px 20px 20px;
}

.modal--photo .modal-footer {
	position: absolute;
	background: transparent;
	bottom: 0;
	right: 0;
	width: 100%;
	margin: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
	will-change: opacity;
	transition: opacity 0.5s;
}
.modal-fade-enter,
.modal-fade-leave-to {
	opacity: 0;
}

.modal-container-enter-active,
.modal-container-leave-active {
	transition: all 0.35s ease-in-out;
}
.modal-container-enter,
.modal-container-leave-to {
	transform: translateY(-30px);
}

.modal-close-btn {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #f1f2f6;
	min-width: 35px;
	width: 35px;
	height: 35px;
	border-radius: 50%;
}

.modal-close-btn i.fap {
	margin: 0;
	color: #2f3542;
}

@media screen and (max-width: 1024px) {
	.modal {
		height: 100%;
		border-radius: 0;
	}

	.modal-container-enter-active,
	.modal-container-leave-active {
		transition: all 0.25s ease-in-out;
	}
	.modal-container-enter,
	.modal-container-leave-to {
		transform: translateX(100%);
	}
}

@media screen and (min-width: 1024px) {
	.modal-mask {
		display: grid;
		place-items: center;
	}

	.modal {
		width: 100%;
		max-width: 600px;
		min-height: auto;
		max-height: 90vh;
	}

	.modal--photo {
		max-width: 90vw;
	}

	.modal-body {
		padding: 15px 20px;
	}

	.medium {
		max-width: 800px;
	}

	.large {
		max-width: 1200px;
	}
}
</style>
