import { HTTP } from '@/core/http.js';

export default {
	get() {
		return HTTP.get('/me').then((response) => {
			return Promise.resolve(response.data);
		});
	},
	put(data) {
		return HTTP.put('/me', data).then((response) => {
			return Promise.resolve(response.data);
		});
	},
	postPasswordReset(email) {
		const data = {
			email,
		};

		return HTTP.post('/user_password_reset', data).then((response) => {
			return Promise.resolve(response.data);
		});
	},
	getPasswordReset(selector, verifier) {
		return HTTP.get(`/user_password_reset/${selector}/${verifier}`).then((response) => {
			return Promise.resolve(response.data);
		});
	},
	putPasswordReset(selector, verifier, password) {
		const data = {
			password,
		};

		return HTTP.put(`/user_password_reset/${selector}/${verifier}`, data).then((response) => {
			return Promise.resolve(response.data);
		});
	},
	getFavorites() {
		return HTTP.get('/me/favorite').then((response) => {
			return Promise.resolve(response.data);
		});
	},
	postFavorite(user) {
		return HTTP.post('/me/favorite', user).then((response) => {
			return Promise.resolve(response.data);
		});
	},
	deleteFavorite(user) {
		return HTTP.delete(`/me/favorite/${user.id}`).then((response) => {
			return Promise.resolve(response.data);
		});
	},
	getFavoritePages() {
		return HTTP.get('/user_favorite_page').then((response) => {
			return Promise.resolve(response.data);
		});
	},
	postFavoritePage(page) {
		return HTTP.post('/user_favorite_page', page).then((response) => {
			return Promise.resolve(response.data);
		});
	},
	putFavoritePage(pageId, page) {
		return HTTP.put(`/user_favorite_page/${pageId}`, page).then((response) => {
			return Promise.resolve(response.data);
		});
	},
	deleteFavoritePage(pageId) {
		return HTTP.delete(`/user_favorite_page/${pageId}`).then((response) => {
			return Promise.resolve(response.data);
		});
	},
	getAwaitTestifyCounts() {
		return HTTP.get('/me/await_testify').then((response) => {
			return Promise.resolve(response.data);
		});
	},
};
