<template>
	<span
		class="fa-checkbox"
		role="checkbox"
		@keydown.enter="toggleListener"
		@keydown.space="toggleListener"
		@click.stop.prevent="toggleListener"
		tabindex="0"
		:aria-checked="localChecked"
	>
		<Fa v-if="indeterminate" key="on" weight="r" icon="minus-square" />
		<Fa v-else-if="localChecked" key="on" weight="r" icon="check-square" />
		<Fa v-else key="off" weight="r" icon="square" />
		<span class="visually-hidden">
			<input type="checkbox" @change="toggleListener" />
		</span>
	</span>
</template>

<script>
export default {
	name: 'FaCheckbox',
	model: {
		prop: 'checked',
		event: 'changed',
	},
	props: {
		checked: [Boolean, Array],
		value: {},
		disabled: {
			type: Boolean,
			default: false,
		},
		indeterminate: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		localChecked() {
			if (Array.isArray(this.checked)) {
				return this.checked.includes(this.value);
			}

			return this.checked;
		},
	},
	methods: {
		toggleListener(event) {
			if (this.disabled) {
				event.stopPropagation();
				return;
			}

			if (Array.isArray(this.checked)) {
				let checked = [].concat(this.checked);
				let index = checked.indexOf(this.value);

				if (index >= 0) {
					checked.splice(index, 1);
				} else {
					checked.push(this.value);
				}

				this.$emit('changed', checked);
			} else {
				this.$emit('changed', !this.checked);
			}
		},
	},
};
</script>

<!--suppress CssUnusedSymbol -->
<style scoped>
.fa-checkbox {
	display: inline-block;
	width: 16px;
	height: 16px;
	cursor: default;
	font-size: 13px;
	font-weight: 700;
}

.fap {
	position: absolute;
	margin: 0;
	font-size: 16px;
}

.fa-square {
	color: #969696;
}

.fa-check-square,
.fa-minus-square {
	color: #169f41;
}

.visually-hidden {
	display: inline-block;
	overflow: hidden;
	width: 1px;
	height: 1px;
	clip: rect(1px, 1px, 1px, 1px);
}
</style>
