export default class HttpApiPagination {
	constructor(headers) {
		let link_previous, link_next, link;

		if (headers.link) {
			headers.link.split(/, ?/).map((part) => {
				const link_info = /<([^;]+)>;rel="([^"]+)"/g.exec(part);

				if (link_info.length < 3) {
					return;
				}

				const url = new URL('http://www' + link_info[1]);
				const search = url.searchParams;

				if (link_info[2] === 'previous') {
					link_previous = search.get('page');
					link = link_previous;
				} else if (link_info[2] === 'next') {
					link_next = search.get('page');
					link = link_next;
				}
			});
		}

		this.previous_url = link_previous;
		this.next_url = link_next;
		this.current_page = parseInt(headers['pagination-page'], 10);
		this.items_total = parseInt(headers['pagination-items-total'], 10);
		this.num_pages = parseInt(headers['pagination-num-pages'], 10);
		this.page_size = 20;

		if (link) {
			link = JSON.parse(atob(link));

			if (link.page && link.page_size) {
				this.page_size = link.page_size;
			}
		}
	}

	createCursor(page) {
		const cursor = btoa(
			JSON.stringify({
				page: page,
				page_size: this.page_size,
			}),
		);

		return {
			page,
			cursor,
		};
	}
}
