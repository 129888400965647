<template>
	<button v-bind="$attrs" class="button"  :class="{ 'button--icon-only': iconOnly, 'button--auto-width': autoWidth, 'button--icon': icon }" @click="$emit('click')">
		<Fa v-if="icon" :weight="iconWeight" :icon="icon" />
		<slot></slot>
	</button>
</template>

<script>
export default {
	name: 'AppButton',
	props: {
		icon: {
			type: String,
			default: '',
		},
		autoWidth: {
			type: Boolean,
			default: false,
		},
		iconWeight: {
			type: String,
			default: 'r',
		},
		iconOnly: {
			type: Boolean,
			default: false,
		},
	},
};
</script>
<style scoped>
	.button--auto-width {
		width: auto;
	}

	.button--icon {
		display: flex;
		align-items: center;
		gap: 4px;
		justify-content: center;
	}

	.button--icon-only {
		aspect-ratio: 1 / 1;
		width: auto;
	}
</style>
