<template>
	<div class="app-info-container">
		<button
			type="button"
			class="button-seamless app-info-button"
			v-tippy="'Information'"
			@click.stop="newInfoListener"
		>
			<Fa weight="r" icon="info-circle" />
		</button>

		<div class="app-info-modal">
			<AppModal v-if="info !== null" :footer="false" @close="cancelInfoListener">
				<template v-slot:header>
					<h1>Information</h1>
				</template>
				<form @submit.prevent.stop="submitListener" v-if="can('system_guide_admin')">
					<div class="content-container">
						<AppFormInput type="text" label="Titel" required v-model="info.name" maxlength="64" />
						<p class="form-group">
							<label for="description">Beskrivning *</label>
							<AppEditor id="description" v-model="info.description" required />
						</p>
					</div>

					<footer>
						<button type="button" class="t-danger" @click.stop="cancelInfoListener">
							<Fa weight="r" icon="times" />
							Avbryt
						</button>
						<button class="t-success">
							<Fa weight="r" icon="cloud-download" />
							Spara
						</button>
					</footer>
				</form>
				<div class="content-container" v-else-if="notFound.length">
					{{ notFound }}
				</div>
				<div class="content-container" v-else>
					<h3>{{ info.name }}</h3>
					<p v-html="info.description"></p>
				</div>
			</AppModal>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import systemGuide from '@/core/systemGuide';
import { catchForApiErrors } from '@/core/http';

export default {
	name: 'AppInfoButton',
	props: {
		uuid: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			info: null,
			notFound: '',
		};
	},
	computed: {
		...mapGetters({
			can: 'auth/can',
		}),
	},
	watch: {
		info(newValue) {
			if (newValue !== null && !Object.keys(newValue).length) {
				this.fetchSystemGuide();
			}
		},
	},
	methods: {
		catchForApiErrors,
		async fetchSystemGuide() {
			try {
				this.info = await systemGuide.get(this.uuid);
			} catch (err) {
				if (err.response.status === 404) {
					this.notFound = 'Information saknas';
					return Promise.resolve();
				}
			}
		},
		newInfoListener() {
			this.info = {};
		},
		cancelInfoListener() {
			this.info = null;
		},
		async submitListener() {
			this.flash().destroyAll();

			let data = { ...this.info };

			try {
				await systemGuide.put(this.uuid, data);
				this.flashSuccess('Information sparades!');

				this.cancelInfoListener();
			} catch (err) {
				this.catchForApiErrors(err);
			}
		},
	},
};
</script>

<style>
.app-info-modal {
	text-align: left;
}
</style>
