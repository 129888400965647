<template>
	<p class="form-group">
		<label :for="$attrs.id" v-if="label">
			{{ label }}
			<span v-if="'required' in $attrs">* </span>
			<span v-if="$attrs.maxlength && !hideMaxLength" class="t-neutral">
				({{ $attrs?.maxlength ?? $attrs?.maxlength - value?.length }})
			</span>
		</label>
		<input type="email" v-bind="$attrs" v-on="listeners" :placeholder="placeholder" :value="value" />
		<span class="hint-text t-neutral">{{ inputHelp }}</span>
		<slot />
	</p>
</template>

<script>
export default {
	name: 'AppFormInputEmail',
	inheritAttrs: false,
	props: {
		value: {
			type: String,
			default: '',
		},
		label: {
			type: String,
			default: '',
		},
		hideMaxLength: {
			type: Boolean,
			default: false,
		},
		inputHelp: {
			type: String,
			default: '',
		},
	},
	computed: {
		listeners() {
			return {
				...this.$listeners,
				input: (event) => {
					this.$emit('input', event.target.value);
				},
			};
		},
		placeholder() {
			return this.$attrs?.placeholder ?? this.label;
		},
	},
};
</script>

<style>
.form-group label {
	padding: 0 0 5px 0;
}

.hint-text {
	display: block;
	font-size: 11px;
	font-style: italic;
	margin-top: 4px;
}
</style>
