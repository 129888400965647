import HttpApiPagination from './HttpApiPagination';

export default class HttpApiResponse {
	constructor(data, headers) {
		this.data = data;
		this.pagination = new HttpApiPagination(headers);
	}
}

export function createEmptyResponse() {
	return new HttpApiResponse([], {
		'pagination-page': 1,
		'pagination-items-total': 0,
		'pagination-num-pages': 0,
	});
}
