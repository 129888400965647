<template>
	<VueSelect
		class="app-select"
		label="serial_number"
		:value="selected"
		:options="options"
		:filterable="false"
		:placeholder="placeholder"
		:disabled="disabled"
		@search="searchListener"
		@input="selectedListener"
	>
		<template #no-options> Skriv för att söka </template>

		<template v-if="showAddress" #selected-option="slotProps">
			<strong>{{ slotProps.serial_number }}</strong>
			<span v-if="slotProps.work_address"> - {{ slotProps.work_address }}</span>
		</template>
		<template v-else #selected-option="slotProps">
			<strong>{{ slotProps.serial_number }}</strong>
			<span v-if="slotProps.name"> - {{ slotProps.name }}</span>
		</template>

		<template v-if="showAddress" #option="slotProps">
			<strong>{{ slotProps.serial_number }}</strong>
			<span v-if="slotProps.work_address"> - {{ slotProps.work_address }}</span>
		</template>
		<template v-else #option="slotProps">
			<strong>{{ slotProps.serial_number }}</strong>
			<span v-if="slotProps.name"> - {{ slotProps.name }}</span>
		</template>
	</VueSelect>
</template>

<script>
import { mapGetters } from 'vuex';
import debounce from 'lodash.debounce';
import project from '@/modules/project/project';

export default {
	name: 'AppFormProjectSearch',
	props: {
		value: null,
		placeholder: String,
		company: Object,
		showAddress: Boolean,
		useSubset: Boolean,
		disabled: Boolean,
	},
	data() {
		return {
			options: [],
			selected: '',
		};
	},
	computed: {
		...mapGetters({
			canAccessAllCompanies: 'auth/canAccessAllCompanies',
		}),
	},
	watch: {
		value: {
			handler(newValue) {
				this.selected = newValue;
			},
			immediate: true,
		},
	},
	methods: {
		searchListener(search, loading) {
			search = search.trim().replace(/\s/g, '');
			if (search === '') {
				return;
			}

			this.search(loading, search, this);
		},
		search: debounce(async (loading, search, vm) => {
			let params = { search };

			if (vm.useSubset) {
				params['subset'] = '1';
			}

			if (vm.company) {
				params['company[]'] = vm.company.id;
			}

			const { data } = await project.getAll(params);
			vm.options = data;
		}, 500),
		selectedListener(selected) {
			this.selected = selected;
			this.$emit('input', this.selected);
		},
	},
};
</script>
