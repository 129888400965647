import { HTTP } from './http';
import HttpApiResponse from './HttpApiResponse';
import store, { moduleCommit } from '@/store';
import * as types from '@/mutationTypes';

HTTP.interceptors.response.use(
	(response) => {
		if (response.headers['app-version']) {
			moduleCommit('app', types.app.SET_APP_VERSION, response.headers['app-version']);
		}

		return response;
	},
	(error) => {
		if (error.response?.status === 401 && store.getters['auth/isLoggedin']) {
			moduleCommit('auth', types.auth.LOGOUT_SUCCESS);
		}

		return Promise.reject(error);
	},
);

export default (repo, loadingOverride = false) => {
	return {
		request(config, hideLoader) {
			if (!hideLoader) {
				moduleCommit('app', types.app.INCREMENT_LOADING_COUNT);
			}

			return HTTP.request(config).finally(() => {
				if (!hideLoader && store.state.loadingCount === 1) {
					setTimeout(() => moduleCommit('app', types.app.DECREMENT_LOADING_COUNT), 5);
				} else if (!hideLoader) {
					moduleCommit('app', types.app.DECREMENT_LOADING_COUNT);
				}
			});
		},

		getAll(params = {}, hideLoader = loadingOverride) {
			return this.request(
				{
					url: `/${repo}`,
					method: 'get',
					params,
				},
				hideLoader,
			).then((response) => {
				return Promise.resolve(new HttpApiResponse(response.data, response.headers));
			});
		},

		getAllNoPagination(params = {}, hideLoader = loadingOverride, shouldCancel = null) {
			if (shouldCancel !== null) {
				if (shouldCancel()) {
					return;
				}
			}

			return this.getAll(params, hideLoader).then((response) => {
				if (response.pagination.next_url) {
					return this.getAllNoPagination(
						{
							...params,
							page: response.pagination.next_url,
						},
						hideLoader,
						shouldCancel,
					).then((nextResponse) => {
						return response.data.concat(nextResponse);
					});
				}

				return response.data;
			});
		},

		get(id, params = {}, hideLoader = loadingOverride) {
			let idParam = '';
			if (typeof id !== 'undefined') {
				idParam = `/${id}`;
			}

			return this.request(
				{
					url: `/${repo}${idParam}`,
					method: 'get',
					params,
				},
				hideLoader,
			).then((response) => Promise.resolve(response.data));
		},

		post(data, hideLoader = loadingOverride) {
			return this.request(
				{
					url: `/${repo}`,
					method: 'post',
					data,
				},
				hideLoader,
			).then((response) => Promise.resolve(response.data));
		},

		put(id, data, hideLoader = loadingOverride) {
			return this.request(
				{
					url: `/${repo}/${id}`,
					method: 'put',
					data,
				},
				hideLoader,
			).then((response) => Promise.resolve(response.data));
		},

		delete(id, hideLoader = loadingOverride) {
			let idParam = '';
			if (typeof id !== 'undefined') {
				idParam = `/${id}`;
			}

			return this.request(
				{
					url: `/${repo}${idParam}`,
					method: 'delete',
				},
				hideLoader,
			).then((response) => Promise.resolve(response.data));
		},
	};
};
