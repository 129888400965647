<template>
	<div class="form-group">
		<label :for="$attrs.id">
			{{ label }}
			<span v-if="required">*</span>
		</label>
		<div class="flex">
			<VueSelect
				class="app-select"
				label="name"
				:value="null"
				:options="options"
				:filterable="false"
				:placeholder="placeholder"
				:inputId="$attrs.id"
				@input="selectedListener"
				style="flex: 1"
			>
				<template v-slot:no-options>Skriv in kontorets namn för att söka</template>
				<template v-slot:option="slotProps">{{ slotProps.name }}</template>
			</VueSelect>
			<button v-if="canSelectAll" type="button" @click="selectAllOfficesListener" style="max-width: 80px">
				Välj alla
			</button>
		</div>

		<ul class="multiselect-list">
			<li v-for="office in offices" :key="office.id">
				{{ office.name }}

				<button v-if="hasOffice(office.id)" @click.prevent="deleteListener(office.id)" class="button-seamless">
					<Fa weight="r" icon="times" class="t-disabled" />
				</button>
			</li>
		</ul>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	name: 'AppFormOfficeSelect',
	props: {
		value: {
			type: Array,
			required: true,
		},
		companies: {
			type: Array,
			required: true,
		},
		label: {
			type: String,
			default: 'Kontor',
		},
		placeholder: {
			type: String,
			default: 'Sök / Lägg till kontor',
		},
		required: {
			type: Boolean,
			default: false,
		},
		canSelectAll: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			selectAllCompanies: false,
		};
	},
	computed: {
		...mapGetters({
			userOffices: 'office/all',
		}),
		offices: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
		options() {
			const selectedFilter = (office) => !this.offices.find((o) => o.id === office.id);
			const inCompaniesFilter = (office) => this.companies.find((c) => c.id === office.company.id);

			return this.userOffices.filter((office) => {
				return selectedFilter(office) && inCompaniesFilter(office);
			});
		},
	},
	methods: {
		async selectAllOfficesListener() {
			this.options.forEach((office) => this.selectedListener(office));
		},
		selectedListener(office) {
			if (!this.offices.length) {
				this.offices.push(office);
			} else if (this.offices.findIndex((o) => o.id === office.id) < 0) {
				this.offices.push(office);
			}
		},
		deleteListener(officeId) {
			this.offices = this.offices.filter((o) => o.id !== officeId);
			this.selectAllCompanies = false;
		},
		hasOffice(id) {
			return this.userOffices.find((office) => office.id === id);
		},
	},
};
</script>
