import httpRepo from '@/core/httpRepo';

export default (userId) => {
	return {
		...httpRepo(`user/${userId}/employment`),
		putSendStart(id) {
			return this.put(`${id}/send_start`, {});
		},
		putAcceptStart(body) {
			return this.put(`accept_start`, body);
		},
		putEnd(id, data) {
			return this.put(`${id}/end`, data);
		},
		deleteEnd(id) {
			return this.delete(`${id}/end`, {});
		},
		putSendEnd(id) {
			return this.put(`${id}/send_end`, {});
		},
		putChangeWorkerType(id) {
			return this.put(`${id}/change_worker_type`, {});
		},
		putAcceptEnd(body) {
			return this.put(`accept_end`, body);
		},
		putManuallyAcceptEnd() {
			return this.put('manually_accept_end', {});
		},
		getCurrent() {
			return this.get('current');
		},
		getUpcoming() {
			return this.get('upcoming');
		},
		getLatest() {
			return this.get('latest');
		},
	};
};
