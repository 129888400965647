<template>
	<p class="form-group-button">
		<button v-bind="$attrs">
			<Fa weight="r" :icon="icon" v-if="icon" />
			<slot></slot>
		</button>
	</p>
</template>

<script>
export default {
	name: 'AppFormButton',
	props: {
		icon: {
			type: String,
			default: '',
		},
	},
};
</script>
