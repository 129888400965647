/**
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
 * @param {number} number
 * @param {object} options
 * @returns {string|any}
 */
export function formatNumber(number, options = { maximumFractionDigits: 2 }) {
	if (typeof number === 'number') {
		return number.toLocaleString('sv', options);
	}

	return number;
}

export function formatIfNumber(number, options = {}) {
	if (typeof number === 'number') {
		return formatNumber(number, options);
	}

	return number;
}

export function formatNoDecimals(number, options = {}) {
	options.maximumFractionDigits = 0;

	return formatIfNumber(number, options);
}

export function formatTwoDecimals(number, options = {}) {
	options.minimumFractionDigits = 2;
	options.maximumFractionDigits = 2;

	return formatIfNumber(number, options);
}

export function formatOneDecimals(number, options = {}) {
	options.minimumFractionDigits = 1;
	options.maximumFractionDigits = 1;

	return formatIfNumber(number, options);
}

export function formatSwedishNumber(number) {
	return new Intl.NumberFormat('sv-SE').format(number);
}

export function toHoursAndMinutes(totalMinutes) {
	const hours = Math.floor(totalMinutes / 60);
	const minutes = totalMinutes % 60;

	return { hours, minutes };
}
