<template>
	<ul class="list" :class="{ 'list--narrow': narrow }">
		<slot></slot>
	</ul>
</template>

<script>
export default {
	name: 'AppList',
	props: {
		narrow: {
			type: Boolean,
			default: false,
		},
	},
};
</script>

<style scoped>
.list {
	padding: 0;
	margin: 0;
	list-style: none;
}
</style>
