import { HTTP } from '@/core/http';

const STORAGE_KEY = 'app_access_token';

/**
 * Used to authenticate the iOS app.
 *
 * IMPORTANT NOTE:
 * Local Storage might not be the best choice for this depending on the longevity
 * we want out of the session lifetime. If the OS starts clearing this too soon
 * we might want to change storage to SQLite and use the SQLite cordova plugin.
 */
const accessToken = {
	enabled: process.env.VUE_APP_CORDOVA === '1',
	shouldBeUsed() {
		return this.enabled;
	},
	hasToken() {
		return this.get() !== null;
	},
	get() {
		return localStorage.getItem(STORAGE_KEY);
	},
	set(token) {
		HTTP.defaults.headers.common.Authorization = 'Bearer ' + token;
		localStorage.setItem(STORAGE_KEY, token);
	},
	clear() {
		localStorage.removeItem(STORAGE_KEY);
		delete HTTP.defaults.headers.common.Authorization;
	},
	readFromSessionResponse(data) {
		if ('access_token' in data) {
			const token = data.access_token;
			this.set(token);
		}
	},
};

export default accessToken;
