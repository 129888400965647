<template>
	<p class="form-group">
		<label :for="$attrs.id" v-if="label" :class="{ 'off-screen': hiddenLabel }">
			{{ label }}
			<span v-if="required">* </span>
			<span v-if="$attrs.maxlength && showMaxLength" class="t-neutral"> ({{ lengthLeft }}) </span>
			<span v-if="$attrs.type === 'number' && $attrs.max >= 0 && showMax" class="t-neutral">
				{{ maxText ? maxText : $attrs.max }}
			</span>
		</label>
		<input
			v-bind="$attrs"
			:placeholder="placeholder ? placeholder : label"
			:value="value"
			:required="required"
			@input="inputListener"
			@change="changeListener"
		/>
		<span v-if="inputHelp" class="hint-text t-neutral">{{ inputHelp }}</span>
		<slot />
	</p>
</template>

<script>
export default {
	name: 'AppFormInput',
	emits: ['input, change'],
	props: {
		label: {
			type: String,
			default: '',
		},
		value: {
			type: [String, Number, Date],
			default: '',
		},
		required: {
			type: Boolean,
			default: false,
		},
		placeholder: {
			type: String,
			default: '',
		},
		showMaxLength: {
			type: Boolean,
			default: true,
		},
		showMax: {
			type: Boolean,
			default: true,
		},
		inputHelp: {
			type: String,
			default: '',
		},
		maxText: {
			type: String,
			default: '',
		},
		hiddenLabel: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		lengthLeft() {
			if (!this.value) {
				return this.$attrs.maxlength;
			}

			return this.$attrs.maxlength - this.value.length;
		},
	},
	methods: {
		inputListener(e) {
			this.$emit('input', e.target.value);
		},
		changeListener(e) {
			this.$emit('change', e);
		},
	},
};
</script>

<style>
.form-group label {
	padding: 0 0 5px 0;
}

.hint-text {
	display: block;
	font-size: 11px;
	font-style: italic;
	margin-top: 4px;
}
</style>
