<template>
	<header class="app-page-header">
		<div class="app-page-header-title-container">
			<Fa weight="r" :icon="icon" class="app-page-header-icon" />

			<h2 v-if="can && editRouteName">
				<RouterLink :to="editRouteName">
					<span>
						<slot></slot>
						<span class="extra-info">
							<slot name="extra-info"></slot>
						</span>
					</span>
					<span v-if="company" class="company">{{ company }}</span>
					<span v-if="companies" class="company">
						<span v-for="(company, index) in companies" :key="index">
							{{ company.name }}
						</span>
					</span>
				</RouterLink>
			</h2>
			<h2 v-else>
				<slot></slot>
				<span class="extra-info">
					<slot name="extra-info"></slot>
				</span>
				<span v-if="company" class="company">{{ company }}</span>
				<span v-if="companies" class="company">
					<span v-for="(company, index) in companies" :key="index">
						{{ company.name }}
					</span>
				</span>
			</h2>
		</div>

		<div class="page-header-links">
			<div class="page-header-links">
				<slot name="extra-routes"></slot>
			</div>

			<RouterLink v-if="can && editRouteName" :to="editRouteName">
				<template v-if="editRouteName">
					<Fa weight="r" icon="pencil-alt" class="t-warning edit-button page-header-link" />
				</template>
			</RouterLink>

			<RouterLink :to="backRouteName" :title="tooltip" class="page-header-link" v-if="backRoute">
				<Fa weight="r" icon="arrow-left" class="t-disabled" />
			</RouterLink>

			<router-link :to="closeRouteName" title="Stäng" class="page-header-link" v-else-if="closeRoute">
				<Fa weight="r" icon="times" class="t-disabled" />
			</router-link>
		</div>
	</header>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	name: 'AppPageHeader',
	props: {
		icon: {
			type: String,
			required: true,
		},
		can: [Boolean, Function],
		tooltip: {
			type: String,
			default: 'Gå tillbaka',
		},
		editRoute: {
			type: String,
		},
		backRoute: {
			type: [String, Boolean],
			default: false,
		},
		closeRoute: {
			type: String,
		},
		closeRouteParams: {
			type: [Object, Boolean],
			default: false,
		},
		company: {
			type: String,
		},
		companies: {
			type: Array,
		},
	},
	computed: {
		...mapGetters({
			authenticatedUser: 'auth/authenticatedUser',
			latestUrlData: 'app/latestUrlData',
		}),
		editRouteName() {
			if (this.editRoute === undefined) {
				return null;
			}

			if (this.$route.name === 'me') {
				return { name: this.editRoute, params: { id: this.authenticatedUser.id } };
			}

			if (this.$route.params.id) {
				return {
					name: this.editRoute,
					params: { id: this.$route.params.id },
				};
			}

			return { name: this.editRoute };
		},
		backRouteName() {
			if (typeof this.backRoute === 'boolean') {
				return this.latestUrlData.fullPath;
			}

			if (this.$route.params.id) {
				return {
					name: this.backRoute,
					params: { id: this.$route.params.id },
				};
			}

			return { name: this.backRoute };
		},
		closeRouteName() {
			const route = { name: this.closeRoute };

			if (this.closeRouteParams) {
				route.params = this.closeRouteParams;
			}

			return route;
		},
	},
};
</script>

<style scoped>
.app-page-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 12px 16px 12px 12px;
	margin: 10px 0 15px;
	background: #fff;
	box-shadow: 0 2px 10px rgba(3, 52, 99, 0.05);
	border-radius: 4px;
}

.app-page-header-title-container {
	display: flex;
	align-items: center;
}

.app-page-header-icon {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 30px;
	height: 30px;
	background: rgba(3, 52, 99, 0.05);
	font-size: 16px;
	border-radius: 4px;
	color: #043464;
	margin-right: 10px;
}

.app-page-header h2 {
	color: #043464;
	font-size: 15px;
	font-weight: 600;
	text-align: left;
	margin: 0;
}

.app-page-header h2 a {
	color: #043464;
}

.page-header-links {
	display: flex;
	align-items: center;
	gap: 14px;
}

.page-header-link {
	font-size: 15px;
}

.extra-info {
	text-transform: capitalize;
	font-weight: 400;
}

.company {
	display: block;
	text-transform: none;
	font-weight: 400;
	font-size: 11px;
	margin-top: 4px;
	border-radius: 40px;
	color: #747d8c;
	text-align: left;
}

.company span + span:before {
	content: ', ';
}

.edit-button {
	margin-left: 4px;
}

@media screen and (max-width: 600px) {
	.app-page-header {
		margin: 0 -10px 15px;
	}
}
</style>
