import axios from 'axios';
import accessToken from '@/core/accessToken';
import store from '@/store';

const axiosOptions = {
	baseURL: process.env.VUE_APP_API_URL,
	headers: {
		Accept: 'application/json',
	},
};

if (accessToken.shouldBeUsed()) {
	axiosOptions.headers['X-Ios'] = true;
	if (accessToken.hasToken()) {
		axiosOptions.headers['Authorization'] = 'Bearer ' + accessToken.get();
	}
} else {
	axiosOptions.withCredentials = true;
}

const HTTP = axios.create(axiosOptions);

function catchApiError(error) {
	let errors = [];

	if (error.response) {
		if (error.response.data && error.response.data.error) {
			const apiError = error.response.data.error;

			if (apiError.details && apiError.details.length) {
				errors = apiError.details;
			} else {
				errors.push(apiError.message);
			}
		} else {
			errors.push('Okänt fel vid anslutning till server');
		}

		if (error.response.headers['sentry-event-id']) {
			errors.push(`Fel-ID: ${error.response.headers['sentry-event-id']}`);
		}
	} else {
		errors.push('Okänt webbläsarfel');
		console.error(error);

		if (store.state.app.lastSentryEventId) {
			errors.push(`Fel-ID: ${store.state.app.lastSentryEventId}`);
		}
	}

	return errors;
}

function catchForApiErrors(err) {
	err = catchApiError(err);

	err.forEach((message) => {
		this.flashError(message, {timeout: 6000});
	});
}

export { HTTP, catchApiError, catchForApiErrors };
