import { HTTP } from '@/core/http.js';
import accessToken from '@/core/accessToken';
import * as types from '@/mutationTypes';
import userEmployment from '@/modules/user/userEmployment';

export default {
	namespaced: true,
	state: () => ({
		authenticatedUser: undefined,
		loginRedirect: undefined,
		timereportLock: undefined,
		employment: undefined,
		warnings: undefined,
	}),
	getters: {
		authenticatedUser(state) {
			return state.authenticatedUser;
		},
		authenticatedUserEmployment(state) {
			return state.employment;
		},
		isLoggedin(state) {
			return !!state.authenticatedUser;
		},
		isAssociationsLoaded(state) {
			return state.authenticatedUser && state.employment !== undefined && state.warnings !== undefined;
		},
		can(state) {
			return (permission, user = state.authenticatedUser) => {
				if (!user) {
					return false;
				}

				return user.permissions.indexOf(permission) >= 0;
			};
		},
		canAny(state, getters) {
			return (...permissions) => {
				return permissions.reduce((carry, permission) => {
					if (!carry) {
						carry = getters.can(permission);
					}

					return carry;
				}, false);
			};
		},
		hasTimereportLock(state) {
			return !!state.timereportLock;
		},
		isEmployed(state) {
			if (typeof state.employment === 'undefined') {
				return undefined;
			}

			if (!state.employment) {
				return false;
			}

			const status = state.employment.status;

			return (
				status === 'ACCEPTED' ||
				status === 'END_DRAFT' ||
				(status === 'ENDED' && !state.employment.past_end_date)
			);
		},
		getEmploymentStatus(state) {
			if (!state.employment) {
				return false;
			}

			return state.employment.status;
		},
		hasWarnings(state) {
			if (state.warnings === undefined) {
				return undefined;
			}

			return state.warnings.length > 0;
		},
		repoCompanyParam(state) {
			if (!state.authenticatedUser) {
				return {};
			}

			return {
				'company[]': state.authenticatedUser.company.id,
			};
		},
		isWhiteCollar(state) {
			return state.authenticatedUser?.worker_type === 'WHITE_COLLAR';
		},
		isBlueCollar(state) {
			return state.authenticatedUser?.worker_type === 'BLUE_COLLAR';
		},
		canAccessAllCompanies(state) {
			return state.authenticatedUser?.permissions.includes('access_all_companies');
		},
		isCeo(state) {
			return state.authenticatedUser?.is_ceo;
		},
		companies(state) {
			return state.authenticatedUser?.companies.map((e) => e.company) || [];
		},
		isEconomy(state) {
			return state.authenticatedUser?.companies.find((e) => e.economy) !== undefined;
		},
		economyCompanies(state) {
			return state.authenticatedUser?.companies.filter((e) => e.economy).map((e) => e.company);
		},
		hasEconomyCompany(state, getters) {
			if (!state.authenticatedUser) {
				return false;
			}

			return (company) => {
				return getters.economyCompanies.find((e) => e.id === company.id) !== undefined;
			};
		},
		/** @returns {Company[]} */
		allowedCompanies(state, getters) {
			return getters.companies;
		},
	},
	actions: {
		setLoginRedirect({ commit }, url) {
			commit(types.auth.SET_LOGIN_REDIRECT, url);
		},
		getSession({ state, commit, dispatch }) {
			if (state.authenticatedUser) {
				return Promise.resolve();
			}

			if (accessToken.shouldBeUsed() && !accessToken.hasToken()) {
				commit(types.auth.LOGOUT_SUCCESS);

				return Promise.resolve();
			}

			return HTTP.get('/session')
				.then((response) => {
					let old_user = state.authenticatedUser;
					commit(types.auth.LOGIN_SUCCESS, response.data);

					if (old_user !== response.data) {
						accessToken.readFromSessionResponse(response.data);
					}

					return dispatch('dispatchAssociations');
				})
				.catch((err) => {
					if (err.response?.status === 401) {
						commit(types.auth.LOGOUT_SUCCESS);
						return;
					}

					throw err;
				});
		},
		login({ commit, dispatch }, postData) {
			commit('app/' + types.app.INCREMENT_LOADING_COUNT, null, { root: true });

			return HTTP.post('/session', postData)
				.then((response) => {
					commit(types.auth.LOGIN_SUCCESS, response.data);
					accessToken.readFromSessionResponse(response.data);

					return dispatch('dispatchAssociations').then(() => {
						return response.data;
					});
				})
				.finally(() => {
					commit('app/' + types.app.DECREMENT_LOADING_COUNT, null, { root: true });
				});
		},
		logout({ commit, dispatch }) {
			commit(types.auth.LOGOUT_SUCCESS);
			dispatch('resetAssociations');

			return HTTP.delete('/session')
				.then(() => {
					// Note that access tokens for the iOS app do not handle impersonation.
					accessToken.clear();

					return HTTP.get('/session')
						.then((response) => {
							commit(types.auth.LOGIN_SUCCESS, response.data);

							return response.data;
						})
						.catch((err) => {
							if (err.response?.status === 401) {
								return;
							}

							throw err;
						});
				})
				.finally(() => {
					dispatch('dispatchAssociations');
				});
		},
		impersonate({ commit, dispatch }, postData) {
			return HTTP.post('/session', postData).then((response) => {
				commit(types.auth.LOGIN_SUCCESS, response.data);

				dispatch('resetAssociations');

				return dispatch('dispatchAssociations').then(() => {
					return response.data;
				});
			});
		},
		resetLocationForImpersonate() {
			location.replace(location.href.split('?')[0]);
		},
		dispatchAssociations({ dispatch, getters }) {
			const promises = [];

			if (getters.can('timereport_lock_system')) {
				promises.push(dispatch('getTimereportLock'));
			}

			promises.push(dispatch('getEmployment').catch((e) => void e));
			promises.push(dispatch('getWarnings').catch((e) => void e));

			return Promise.all(promises);
		},
		resetAssociations({ commit }) {
			commit(types.auth.SET_TIMEREPORT_LOCK, undefined);
			commit(types.auth.SET_EMPLOYMENT, undefined);
			commit(types.auth.SET_WARNINGS, undefined);
		},
		getTimereportLock({ state, commit }, force = false) {
			if (state.timereportLock !== undefined && force === false) {
				return Promise.resolve();
			}

			return HTTP.get('/session/timereport_lock')
				.then((response) => {
					commit(types.auth.SET_TIMEREPORT_LOCK, response.data);

					return Promise.resolve();
				})
				.catch((err) => {
					if (err.response?.status === 404) {
						commit(types.auth.SET_TIMEREPORT_LOCK, null);
						return;
					}

					throw err;
				});
		},
		async getEmployment({ state, commit, getters }, force = false) {
			if (!getters.isLoggedin) {
				return Promise.reject();
			}

			if (!force && state.employment !== undefined) {
				return Promise.resolve();
			}

			try {
				const response = await userEmployment(state.authenticatedUser.id).getUpcoming();
				commit(types.auth.SET_EMPLOYMENT, response);
			} catch (err) {
				// Do nothing.
			}

			if (state.employment !== undefined) {
				return;
			}

			try {
				const response = await userEmployment(state.authenticatedUser.id).getCurrent();
				commit(types.auth.SET_EMPLOYMENT, response);
			} catch (err) {
				// Do nothing.
			}

			if (state.employment !== undefined) {
				return;
			}

			try {
				// Latest employment. But is probably not an active employment.
				const response = await userEmployment(state.authenticatedUser.id).getLatest();
				commit(types.auth.SET_EMPLOYMENT, response);
			} catch (err) {
				// Do nothing.
			}
		},
		getWarnings({ state, commit, getters }, force = false) {
			if (!getters.isLoggedin) {
				return Promise.reject();
			}

			if (!force && state.warnings !== undefined) {
				return Promise.resolve();
			}

			return HTTP.get(`/user/${state.authenticatedUser.id}/employment_warning?status=SENT`)
				.then((response) => {
					commit(types.auth.SET_WARNINGS, response.data);
				})
				.catch((e) => void e);
		},
	},
	mutations: {
		[types.auth.LOGIN_SUCCESS](state, payload) {
			state.authenticatedUser = payload;
		},
		[types.auth.LOGOUT_SUCCESS](state) {
			state.authenticatedUser = null;
		},
		[types.auth.SET_LOGIN_REDIRECT](state, payload) {
			state.loginRedirect = payload;
		},
		[types.auth.SET_TIMEREPORT_LOCK](state, payload) {
			state.timereportLock = payload;
		},
		[types.auth.SET_EMPLOYMENT](state, payload) {
			state.employment = payload;
		},
		[types.auth.SET_WARNINGS](state, payload) {
			state.warnings = payload;
		},
	},
};
